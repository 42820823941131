import React, {useState} from 'react'
import loadable from "@loadable/component";
import useInfiniteScroll from 'react-infinite-scroll-hook';

const ArticlePreview = loadable(() => import("../../templates/articlePreview.amp"));

const ARRAY_SIZE = 5;
const WhatNextMobile = (props) => {

    function loadItems(startCursor = 0) {
        let newArray = [];
        for (let i = startCursor; i < startCursor + ARRAY_SIZE; i++) {
            const newItem = props.recommendedPosts[i];
            if (!newItem) {
                break;
            }
            newArray = [...newArray, newItem];
        }

        return {hasNextPage: startCursor < props.recommendedPosts.length - 1, data: newArray};
    }

    function useLoadItems() {
        const [loading, setLoading] = useState(false);
        const [items, setItems] = useState([]);
        const [hasNextPage, setHasNextPage] = useState(true);
        const [error, setError] = useState(null);

        async function loadMore() {
            setLoading(true);
            try {
                const {data, hasNextPage: newHasNextPage} = loadItems(
                    items.length,
                );
                setItems((current) => {
                    return [...current, ...data]
                });
                setHasNextPage(newHasNextPage);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }

        return {loading, items, hasNextPage, error, loadMore};
    }

    const {loading, items, hasNextPage, error, loadMore} = useLoadItems();
    const [infiniteRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        // When there is an error, we stop infinite loading.
        // It can be reactivated by setting "error" state as undefined.
        disabled: !!error,
        // `rootMargin` is passed to `IntersectionObserver`.
        // We can use it to trigger 'onLoadMore' when the sentry comes near to become
        // visible, instead of becoming fully visible on the screen.
        rootMargin: '0px 0px 150px 0px',
    });

    return (
        <div className="container container--halves container--lined mobile-what-next">
            <div className="popular-posts-top">WHAT TO READ NEXT...</div>

            <div className="container-grid">
                {items.map((item, index) => {
                    return <div className="container-grid__item" key={index}>
                        <ArticlePreview
                            customClass="teaser-card--left-aligned-img"
                            data={item}
                            noBody={true}
                            noAuthor={true}
                            wrappedImg={false}/>
                    </div>
                })}
            </div>
            {hasNextPage && (
                <div ref={infiniteRef}>
                    Loading..
                </div>
            )}
        </div>
    )
}

export default WhatNextMobile
